import React, {Fragment, useContext} from 'react'
import { Container, Flex, Box, Link, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { buildLinkProps } from '@solid-ui-components/ContentButtons'
import { ModalContext } from '@solid-ui-components/Modal'
import { TabsContext } from '@solid-ui-components/Tabs'

const styles = {
  logo: {
    width: [100, 150],
    height: [50, 75]
  }
}

const CompaniesBlock01 = ({ content }) => {
  const { text, collection, buttons } = content
  const { setActiveModal } = useContext(ModalContext)
  const { setActiveTab } = useContext(TabsContext)

  return (
    <Container sx={{ textAlign: `center` }}>
      <ContentText content={text} />
      {text && collection && <Divider />}
      <Flex sx={{ flexWrap: `wrap`, m: -3 }}>
        {collection?.map(({ text, icon, container }, index) => {
          const { Component, linkProps } = container ? buildLinkProps({
            content: { ...container.link, variant: "links.any" },
            setActiveModal,
            setActiveTab
          }) : {Component: Fragment, linkProps: {}}
          return (
            <Box key={`item-${index}`} sx={{ flexGrow: 1, p: [2, 3] }}>
              <Reveal
                effect='fadeInGrow'
                delay={0.2 * (index + 2)}
                title={text?.[0]?.text}
              >
                <Component
                  {...linkProps}
                >
                  {icon?.src ? (
                    <img src={icon.src} alt={icon.alt} />
                  ) : (
                    <ContentText content={text?.[0]} variant='h6' mb='0' />
                  )}
                </Component>
              </Reveal>
            </Box>
          )
        })}
      </Flex>
      {buttons && (
        <>
          <Divider space={3} />
          <ContentButtons content={buttons} />
        </>
      )}
    </Container>
  )
}

export default WithDefaultContent(CompaniesBlock01)
