import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '../components/Hero/Block01'
import Companies from '../components/Companies/Block01'
import Features from '@solid-ui-blocks/Features/Block05'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block07'
import WhyChooseUs from '@solid-ui-blocks/Features/Block01'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block07'
import HowItWorks from '@solid-ui-blocks/FeaturesWithPhoto/Block04'
import Gallery from '@solid-ui-blocks/Blog/Block01'
import GetStarted from '../components/CallToAction/Block01'
import Footer from '../components/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  // useEffect(() => {
  //   const textWithMe = document.querySelector("div#hero  div.css-1jt5jlc-ContentImages div div.gatsby-image-wrapper.gatsby-image-wrapper-constrained.css-1eda1gt-ImageComponent");
  //   textWithMe.on("click", () => {
  //     window.location.href = 'https://deegmiterko.com';
  //   })
  // }, [])

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Meet' />
      {/* Blocks */}
      <Divider space='1' />
      <Container variant='wide' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Divider space='3' />
      <Features content={content['story']} />
      <Divider space='3' />
      <Companies content={content['platforms']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.featureOneContainer}>
        <HowItWorks content={content['how-it-works']} />
      </Container>
      <Divider space='4' />
      <Container>
        <Gallery content={content['gallery']} />
      </Container>
      <Divider space='4' />
      <GetStarted content={content['get-started']} />
      <Divider space='4' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepagePageContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
